import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IsLoading } from "../../../common/components/AppState/IsLoading";
import { ButtonSquareIconsProject } from "../../../common/components/Button";
import { useAppDispatch } from "../../../common/state/hooks";
import { openConfirmationModal } from "../../../common/state/slice/modal/modalSlice";
import handleThreeDotsButtonClickForProjectsAndProjectGroups from "../../../common/util/handleThreeDotsButtonClickForProjectsAndProjectGroups";
import { IProject } from "../../../model/model";
import {
  getCurrentTemplateId,
  INewProjectFormValues,
  setCurrentTemplateId,
  setCurrentProjectFromId,
} from "../state/projectsSlice";
import { useTranslation } from "react-i18next";

const TemplateCards = ({
  templates,
  tagSlug,
  control,
  setValue,
  insideOfProject,
}: {
  templates: IProject[];
  setValue: UseFormSetValue<INewProjectFormValues>;
  control: Control<INewProjectFormValues, object>;
  tagSlug: string;
  insideOfProject?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentTemplateId = useSelector(getCurrentTemplateId);

  return (
    <>
      {templates.map((template) => (
        <>
          {insideOfProject ? (
            <Controller
              key={template.id}
              name="projectId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <IsLoading
                  key={`template-${template.id}`}
                  componentId={`template-${template.id}`}
                  spinnerPlaceholder
                >
                  <ButtonSquareIconsProject
                    width="w-56"
                    height={"h-32"}
                    threeDotsMenuItemsPosition="top-3 right-3"
                    type="button"
                    {...field}
                    onClick={() => {
                      dispatch(setCurrentTemplateId(template.id));
                      setValue("projectId", Number(template.id));
                    }}
                    currentProject={template.id}
                    project={template}
                    handleThreeDotButtonClick={(e: any) => {
                      dispatch(setCurrentTemplateId(template.id));
                      handleThreeDotsButtonClickForProjectsAndProjectGroups({
                        payload: e,
                        deleteCallback: () => {
                          dispatch(openConfirmationModal("deleteTemplateConfirmation"));
                        },
                        t,
                        navigate,
                      });
                    }}
                    isSelected={Number(currentTemplateId) === Number(template.id)}
                    showThreeDotsButton={tagSlug === "custom-templates"}
                    isCustomTemplateCard={tagSlug === "custom-templates"}
                    tagSlug={tagSlug}
                  />
                </IsLoading>
              )}
            />
          ) : (
            <IsLoading
              key={`template-${template.id}`}
              componentId={`template-${template.id}`}
              spinnerPlaceholder
            >
              <ButtonSquareIconsProject
                width="w-56"
                height={"h-32"}
                threeDotsMenuItemsPosition="top-3 right-3"
                type="button"
                onClick={() => {
                  dispatch(setCurrentTemplateId(template.id));
                  dispatch(setCurrentProjectFromId(template.id));
                  navigate(`../${template.id}`);
                }}
                currentProject={template.id}
                project={template}
                handleThreeDotButtonClick={(e: any) => {
                  dispatch(setCurrentTemplateId(template.id));
                  handleThreeDotsButtonClickForProjectsAndProjectGroups({
                    payload: e,
                    deleteCallback: () => {
                      dispatch(openConfirmationModal("deleteTemplateConfirmation"));
                    },
                    t,
                    navigate,
                  });
                }}
                isSelected={false}
                showThreeDotsButton
                isCustomTemplateCard={tagSlug === "custom-templates"}
                tagSlug={tagSlug}
              />
            </IsLoading>
          )}
        </>
      ))}
    </>
  );
};

export default TemplateCards;
