import { Menu, Transition } from "@headlessui/react";
import Icon, { IconName } from "./Icon";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/common/state/hooks";
import {
  getSelectedProjectGroup,
  setCurrentProjectGroupAsEdited,
  setSelectedProjectGroupFromId,
} from "src/features/projectGroups/state/projectGroupsSlice";
import { getCurrentProject } from "src/features/projects/state/projectsSlice";
import DoubleDropdown, { SubMenuItem } from "./DoubleDropdownThreeDots";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ThemeIcon from "./ThemeIcon";
import icon360 from "src/assets/icon360.png";
import ChevronRightIconButton from "../Button/ChevronRightIconButton";

export interface menuItem {
  itemId?: string;
  menuItemText: string;
  icon?: IconName;
  disabled?: boolean;
  disabledTooltipText?: string;
  isEndUser?: boolean;
  strokeColor?: string;
  className?: string;
  iconImage?: string;
}

export type menuItems = Array<menuItem>;

const ThreeDotsDropdownMenu = ({
  type = "project",
  handleClick,
  onMenuOpen,
  menuItems,
  isEndUser,
  strokeColor = "",
  className = "h-6 w-6",
  menuPosition = "below",
  setEditedProjectId = () => {
    [];
  },
  projectId,
  currentProjectGroupElement,
  setEditedProjectGroupId = () => {
    [];
  },
}: {
  type?: "projectGroup" | "project";
  handleClick: (item: any, index: number) => void;
  onMenuOpen?: () => void;
  menuItems: menuItems;
  isEndUser?: boolean;
  strokeColor?: string;
  className?: string;
  menuPosition?: "above" | "below";
  projectId?: number;
  setEditedProjectId?: (id: any) => void;
  currentProjectGroupElement?: any;
  setEditedProjectGroupId?: (id: any, projectGroup: any) => void;
}) => {
  const menuPositionClasses = menuPosition === "above" ? "bottom-6" : "-top-3";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentProjectGroup = useSelector(getSelectedProjectGroup);
  const currentProject = useSelector(getCurrentProject);

  const [activeSubMenu, setActiveSubMenu] = useState<"project" | "projectGroup" | null>(null);
  const [isHoveringDoubleDropdown, setIsHoveringDoubleDropdown] = useState(false);
  const [hoverMenuItem, setHoverMenuItem] = useState(false);
  const [subMenuPosition, setSubMenuPosition] = useState<"left" | "right">("right");

  useEffect(() => {
    if (!hoverMenuItem && !isHoveringDoubleDropdown) {
      setActiveSubMenu(null);
    }
  }, [hoverMenuItem, isHoveringDoubleDropdown]);

  const projectGroupSubMenuItems: SubMenuItem[] = [
    { id: "edit-name", text: t("tooltip.editNameCourse"), icon: "PencilIcon" },
    {
      id: "edit-theme",
      text: t("tooltip.editTheme"),
      icon: () => (
        <span className="flex stroke-green-500 justify-center h-5 w-5 mr-2">
          <ThemeIcon />
        </span>
      ),
    },
    { id: "edit-thumbnail", text: t("tooltip.editThumbnail"), icon: "PhotographIcon" },
    {
      id: "edit-image",
      text: t("tooltip.editImage"),
      icon: () => <img src={icon360} alt="360 Icon" className="w-5 h-5 mr-2" />,
    },
  ];

  const projectSubMenuItems: SubMenuItem[] = [
    { id: "edit-name", text: t("tooltip.editModuleName"), icon: "PencilIcon" },
    { id: "edit-language", text: t("tooltip.editLanguage"), icon: "GlobeIcon" },
    { id: "edit-thumbnail", text: t("tooltip.editThumbnail"), icon: "PhotographIcon" },
  ];

  const handleSubMenuClick = (id: string) => {
    if (activeSubMenu === "project") {
      handleDropdownProject(id);
    } else if (activeSubMenu === "projectGroup") {
      handleDropdown(id);
    }
    setActiveSubMenu(null);
  };

  const handleDropdown = (selectedAction: string) => {
    if (currentProjectGroupElement) {
      dispatch(setSelectedProjectGroupFromId(currentProjectGroupElement));
    }
    dispatch(setCurrentProjectGroupAsEdited());

    switch (selectedAction) {
      case "edit-name":
        navigate(`/courses/${currentProjectGroupElement}/edit-name`);
        break;
      case "edit-thumbnail":
        navigate(`/courses/${currentProjectGroupElement}/edit-thumbnail`);
        break;
      case "edit-image":
        navigate(`/courses/${currentProjectGroupElement}/edit-image`);
        break;
      case "edit-theme":
        navigate(`/courses/${currentProjectGroupElement}/edit-theme`);
        break;
      default:
        break;
    }
  };

  const handleDropdownProject = (selectedAction: string) => {
    switch (selectedAction) {
      case "edit-name":
        navigate(`/courses/${currentProjectGroup.id}/list/${currentProject.id}/edit-name`);
        break;
      case "edit-thumbnail":
        navigate(`/courses/${currentProjectGroup.id}/list/${currentProject.id}/edit-thumbnail`);
        break;
      case "edit-language":
        navigate(`/courses/${currentProjectGroup.id}/list/${currentProject.id}/edit-language`);
        break;
      default:
        break;
    }
  };

  return (
    <Menu
      as="div"
      className={`${isEndUser ? "mr-1 absolute right-1 top-2" : "relative"} z-10 h-6 w-6`}
    >
      {({ open }: { open: boolean }) => {
        if (open && onMenuOpen) {
          onMenuOpen();
        }
        return (
          <>
            <Menu.Button
              onClick={() => {
                if (projectId) {
                  setEditedProjectId(projectId);
                }
                if (currentProjectGroupElement) {
                  setEditedProjectGroupId(
                    currentProjectGroupElement.id,
                    currentProjectGroupElement,
                  );
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                strokeWidth="0.5"
                stroke={strokeColor}
                className={className}
                viewBox="0 0 45 40"
                width={"50px"}
              >
                <style>
                  {`
                    svg:hover .circle {
                      fill: #009A6D;
                    }
                  `}
                </style>
                <circle cx="6" cy="15" r="5" fill="#00C889" className="circle"></circle>
                <circle cx="21" cy="15" r="5" fill="#00C889" className="circle"></circle>
                <circle cx="36" cy="15" r="5" fill="#00C889" className="circle"></circle>
              </svg>
            </Menu.Button>

            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              className={"z-50 overflow-visible"}
            >
              <Menu.Items
                className={`z-50 overflow-visible absolute ${menuPositionClasses} right-0 w-max origin-${
                  menuPosition === "above" ? "bottom-right" : "top-right"
                } bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none`}
              >
                <div className="py-1">
                  {menuItems.length > 0 &&
                    menuItems.map((menuItem, i) => {
                      const isDeleteButton = menuItem.icon === "TrashIcon";
                      const isModifyButton = menuItem.menuItemText === t("general.modify");
                      const disabled = menuItem?.disabled;
                      const disabledTooltipText =
                        disabled && Object.keys(menuItem).includes("disabledTooltipText")
                          ? menuItem.disabledTooltipText
                          : "";
                      const menuItemTextToolTip = menuItem.disabledTooltipText
                        ? menuItem.disabledTooltipText
                        : "";
                      const tooltipForEnableButton = Object.keys(menuItem).includes(
                        "disabledTooltipText",
                      )
                        ? menuItemTextToolTip
                        : "";

                      return (
                        <Menu.Item key={i}>
                          <div
                            onMouseEnter={() => {
                              const dropdownElement = document.querySelector(".menu-item-selector");
                              if (dropdownElement) {
                                const rect = dropdownElement.getBoundingClientRect();
                                if (rect.right > window.innerWidth) {
                                  setSubMenuPosition("left");
                                } else {
                                  setSubMenuPosition("right");
                                }
                              }
                              setHoverMenuItem(true);
                              if (isModifyButton) setActiveSubMenu(type);
                            }}
                            onMouseLeave={() => setHoverMenuItem(false)}
                            className="relative"
                          >
                            <Tooltip
                              title={
                                disabled ? (disabledTooltipText as string) : tooltipForEnableButton
                              }
                              placement="right-end"
                            >
                              <button
                                type="button"
                                disabled={disabled}
                                onClick={() => {
                                  if (!isModifyButton) {
                                    handleClick(menuItem, i);
                                  }
                                }}
                                className={`${
                                  isDeleteButton && !disabled
                                    ? "text-red-900"
                                    : isModifyButton
                                    ? "font-bold text-gray-900"
                                    : "text-gray-900"
                                }
                          ${disabled ? "cursor-not-allowed text-gray-400" : ""}
                          group flex w-full items-center rounded-md px-3 py-1 text-sm`}
                              >
                                {menuItem.iconImage ? (
                                  <img
                                    src={menuItem.iconImage}
                                    alt={menuItem.menuItemText}
                                    className="w-5 h-5 mr-2"
                                  />
                                ) : (
                                  <Icon
                                    icon={menuItem.icon}
                                    className={`mr-2 w-5 h-5 ${
                                      disabled
                                        ? "stroke-gray-500"
                                        : isDeleteButton
                                        ? "stroke-red-500"
                                        : "stroke-green-500"
                                    }`}
                                  />
                                )}
                                {menuItem.menuItemText}
                                {isModifyButton && (
                                  <span>
                                    <ChevronRightIconButton className="w-4 ml-9 h-4" />
                                  </span>
                                )}
                              </button>
                            </Tooltip>
                            {isModifyButton && activeSubMenu === type && (
                              <div
                                onMouseEnter={() => setIsHoveringDoubleDropdown(true)}
                                onMouseLeave={() => {
                                  setIsHoveringDoubleDropdown(false);
                                }}
                              >
                                {type === "projectGroup" && (
                                  <DoubleDropdown
                                    subMenuItems={projectGroupSubMenuItems}
                                    onSubMenuClick={handleSubMenuClick}
                                    subMenuPosition={subMenuPosition}
                                    type="projectGroup"
                                  />
                                )}
                                {type === "project" && (
                                  <DoubleDropdown
                                    subMenuItems={projectSubMenuItems}
                                    onSubMenuClick={handleSubMenuClick}
                                    subMenuPosition={subMenuPosition}
                                    type="project"
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </Menu.Item>
                      );
                    })}
                </div>
              </Menu.Items>
            </Transition>
          </>
        );
      }}
    </Menu>
  );
};

export default ThreeDotsDropdownMenu;
