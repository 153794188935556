import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";
import apiUrls from "src/api";
import ModalStandard from "src/common/components/Modal/ModalStandard";
import Spinner from "src/common/components/Loader/Spinner";
import { useAppDispatch } from "src/common/state/hooks";
import { closeStandardModal } from "src/common/state/slice/modal/modalSlice";
import { jwtSelector } from "src/common/state/selectors/authSelector";
import { getShowStandardModal } from "src/common/state/slice/modal/modalSlice";

const DeepLinkModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentJwt = useSelector(jwtSelector);
  const modalState = useSelector(getShowStandardModal);
  const isModalVisible = modalState.id === "modalDeepLink" && modalState.show;

  const [jwtSigned, setJwtSigned] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isModalVisible || jwtSigned) return;

    const fetchJwt = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(apiUrls.auth.generateVrStudioJwt, null, {
          headers: {
            Authorization: `Bearer ${currentJwt}`,
          },
        });
        setJwtSigned(response.data);
      } catch (error) {
        console.error("JWT fetch failed, nya~ 😿", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJwt();
  }, [isModalVisible, currentJwt, jwtSigned]);

  const handleOpenLink = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
    dispatch(closeStandardModal("modalDeepLink"));
  };

  return (
    <ModalStandard id="modalDeepLink" className="w-[50%] h-auto" showCloseButton>
      <div className="flex flex-col items-center space-y-4">
        <h2 className="text-xl font-bold text-center">{t("sidebar.deeplinkModal.title")}</h2>

        <div className="h-[60px] flex items-center justify-center">
          {isLoading || !jwtSigned ? (
            <Spinner />
          ) : (
            <div className="flex flex-row justify-center space-x-8">
              <button
                className="btn-primary-fill"
                onClick={() => handleOpenLink(`wixarstudio://${jwtSigned}`)}
              >
                {t("sidebar.deeplinkModal.gotoNativeAppButton")}
              </button>
              <button
                className="btn-primary-fill"
                onClick={() =>
                  handleOpenLink(
                    `https://app.wixar.io/StudioVR_1311_WebGL/index.html?token=${jwtSigned}`,
                  )
                }
              >
                {t("sidebar.deeplinkModal.gotoWebGLAppButton")}
              </button>
            </div>
          )}
        </div>
      </div>
    </ModalStandard>
  );
};

export default DeepLinkModal;
