import { useEffect, useRef, useState } from "react";
import ModernGenericTable from "src/common/components/ModernGenericTable/ModernGenericTable";
import { useTranslation } from "react-i18next";
import { IProjectTranslationState } from "src/model/model";
import { ProgressBarWithValues } from "src/common/components/ProgressBarWithValues/ProgressBarWithValues";
import LanguageIcon from "src/common/components/Icon/LanguageIcon";
import AutoTranslateCheckbox from "src/common/components/ProgressBarWithValues/AutoTranslateCheckbox";
import { Tooltip } from "@mui/material";
import { closeStandardModal } from "src/common/state/slice/modal/modalSlice";
import { useAppDispatch } from "src/common/state/hooks";
import { translateProject } from "src/features/languages/state/languagesSlice";
import { useSelector } from "react-redux";
import {
  fetchProjectTranslationState,
  getCurrentProject,
} from "src/features/projects/state/projectsSlice";
import { IsLoading } from "src/common/components/AppState/IsLoading";

interface ModernAutoTranslationSelectorProps {
  rows?: IProjectTranslationState[];
  sourceLanguageId: string;
  selectedLanguages: string[];
  setSelectedLanguages?: (selectedIds: string[]) => void;
}

export const MODERN_AUTO_TRANSLATION_SELECTOR_COMPONENT_ID =
  "MODERN_AUTO_TRANSLATION_SELECTOR_COMPONENT_ID";

const ModernAutoTranslationSelector = ({
  rows = [],
  sourceLanguageId,
  selectedLanguages,
  setSelectedLanguages = () => null,
}: ModernAutoTranslationSelectorProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentProject = useSelector(getCurrentProject);
  const [tableHeight, setTableHeight] = useState(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  // Define localized header names
  const localizedLanguages = t("pages.editProjectLanguage.languages");
  const localizedTranslatedInteractions = t("pages.editProjectLanguage.translatedInteractions");

  const sourceLanguageNumberOfTranslatedInteractions = rows.find(
    (pts) => pts.isSourceLanguage,
  ).currentPldLength;
  const isThereAtLeastOneSelectedLanguage = selectedLanguages.length;

  // Build row objects using the localized keys for the respective columns
  const transformedRows = rows.map((pts: IProjectTranslationState) => ({
    id: pts.languageId.toString(),
    [localizedLanguages]: t(`general.languages.IETF_BCP_47.${pts.languageIetf}`),
    "ISO 639": pts.languageIso,
    [localizedTranslatedInteractions]: {
      current: pts.currentPldLength,
      total: pts.maximumPldLength,
    },
    isSourceLanguage: pts.isSourceLanguage,
    isAutoTranslationSupported: pts.isAutoTranslationSupported,
  }));

  useEffect(() => {
    const calculateHeight = () => setTableHeight(Math.round(window.innerHeight * 0.5));
    calculateHeight();
    window.addEventListener("resize", calculateHeight);
    return () => window.removeEventListener("resize", calculateHeight);
  }, []);

  const languageRenderer = (row: any) => <span className="ml-4">{row[localizedLanguages]}</span>;

  const isoRenderer = (row: any) => <span className="italic text-gray-500">{row["ISO 639"]}</span>;

  const progressRenderer = (row: any) => {
    const { current, total } = row[localizedTranslatedInteractions];
    return (
      <div className="w-full h-[30px] flex items-center justify-center pl-4 pr-4">
        <ProgressBarWithValues
          current={current}
          total={total}
          animated={false}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  };

  const actionRenderer = (row: any) => {
    if (row.isSourceLanguage || row.id === sourceLanguageId) {
      return (
        <div className="text-xl flex justify-center">
          <LanguageIcon height={20} width={20} stroke="rgb(0 200 137)" />
        </div>
      );
    } else if (!row.isAutoTranslationSupported) {
      return <></>;
    } else {
      const isSelected = selectedLanguages.includes(row.id);
      const toggleSelection = () => {
        const newSelected = isSelected
          ? selectedLanguages.filter((id) => id !== row.id)
          : [...selectedLanguages, row.id];
        setSelectedLanguages(newSelected);
      };

      const thisLanguageTranslatedInteractions = row[localizedTranslatedInteractions].current;

      return (
        <AutoTranslateCheckbox
          isSelected={isSelected}
          onToggle={toggleSelection}
          disabled={
            sourceLanguageNumberOfTranslatedInteractions <= thisLanguageTranslatedInteractions
          }
          tooltip={t("tooltip.cannotAutoTranslateThatWhichDoesNotExist")}
        />
      );
    }
  };

  return (
    <div className="flex flex-col">
      <IsLoading
        spinnerPlaceholder
        componentId={MODERN_AUTO_TRANSLATION_SELECTOR_COMPONENT_ID}
        showSuccess={false}
      >
        <ModernGenericTable
          key={tableHeight}
          maxTableHeightPx={tableHeight}
          colHeaders={[localizedLanguages, "ISO 639", localizedTranslatedInteractions, ""]}
          rows={transformedRows}
          className="h-full overflow-x-hidden"
          ref={tableContainerRef}
          checkAll={false}
          showSearchBar
          selectedRowIds={selectedLanguages}
          nonDeselectableRowIds={sourceLanguageId !== "-1" ? [sourceLanguageId] : []}
          headerCellClasses={{
            [localizedLanguages]: "ml-4",
          }}
          columnAlignments={{
            [localizedLanguages]: "left",
            "ISO 639": "center",
            [localizedTranslatedInteractions]: "center",
            "": "center",
          }}
          columnProportions={{
            [localizedLanguages]: "28%",
            "ISO 639": "14%",
            [localizedTranslatedInteractions]: "auto",
            "": "28%",
          }}
          customCellRenderers={{
            [localizedLanguages]: languageRenderer,
            "ISO 639": isoRenderer,
            [localizedTranslatedInteractions]: progressRenderer,
            "": actionRenderer,
          }}
        />
        <div className="flex flex-row mt-4 justify-end">
          <button
            className="btn-primary-outline w-28 mr-4"
            onClick={() => dispatch(closeStandardModal("projectAutomaticTranslation"))}
          >
            {t("general.cancel")}
          </button>
          <Tooltip
            title={
              isThereAtLeastOneSelectedLanguage
                ? ""
                : t("tooltip.youNeedToSelectTargetLanguagesToUseAutoTranslation")
            }
          >
            <button
              className={`btn-primary-fill w-28 mr-4 ${
                isThereAtLeastOneSelectedLanguage ? "" : "cursor-not-allowed"
              }`}
              disabled={!isThereAtLeastOneSelectedLanguage}
              onClick={() => {
                if (isThereAtLeastOneSelectedLanguage) {
                  dispatch(
                    translateProject({
                      componentId: MODERN_AUTO_TRANSLATION_SELECTOR_COMPONENT_ID,
                      projectId: currentProject.id,
                      targetLanguageIds: selectedLanguages.map((languageId) => Number(languageId)),
                      cb: () => {
                        dispatch(
                          fetchProjectTranslationState({
                            componentId: MODERN_AUTO_TRANSLATION_SELECTOR_COMPONENT_ID,
                            projectId: currentProject.id,
                          }),
                        );
                        setSelectedLanguages([]);
                      },
                    }),
                  );
                }
              }}
            >
              {t("general.confirm")}
            </button>
          </Tooltip>
        </div>
      </IsLoading>
    </div>
  );
};

export default ModernAutoTranslationSelector;
