import React, { useState, useEffect, forwardRef, ChangeEvent } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Checkbox,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  LockClosedIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import LanguageIcon from "../Icon/LanguageIcon";
import { useTranslation } from "react-i18next";

export interface NotSoModernGenericTableProps {
  maxTableHeightPx?: number;
  rows: any[];
  colHeaders: string[]; // e.g. ["Name", "ISO 639"]
  className?: string;
  cellClassName?: string;
  emptyPlaceholder?: React.ReactNode;
  checkAll?: boolean;
  showSearchBar?: boolean;
  filterActivated?: boolean;
  onSelectionChange?: (selectedRowIds: (string | number)[]) => void;
  customSortFunctions?: {
    [key: string]: (a: any, b: any, direction: "asc" | "desc") => number;
  };
  selectedRowIds: (string | number)[];
  nonDeselectableRowIds?: (string | number)[];
  customCellRenderers?: {
    [columnHeader: string]: (row: any) => React.ReactNode;
  };
  // Optional prop for aligning columns
  columnAlignments?: { [columnHeader: string]: "left" | "center" | "right" };
  // NEW PROP: controls whether unchecked rows (and their checkboxes) are shown
  showCheckboxesForUnselected?: boolean;
}

const NotSoModernGenericTable = forwardRef<HTMLDivElement, NotSoModernGenericTableProps>(
  (
    {
      maxTableHeightPx = 0,
      rows,
      colHeaders,
      className = "",
      cellClassName = "",
      emptyPlaceholder = "No data available",
      checkAll = false,
      showSearchBar = false,
      filterActivated = true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSelectionChange = () => {},
      customSortFunctions = {},
      selectedRowIds,
      nonDeselectableRowIds,
      customCellRenderers,
      columnAlignments,
      // default to true for backwards compatibility
      showCheckboxesForUnselected = true,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [searchString, setSearchString] = useState("");
    const [sortedColumn, setSortedColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [displayRows, setDisplayRows] = useState<any[]>(rows);

    // This value is only used to calculate the scrollable body height.
    const headerHeight = 56;

    useEffect(() => {
      let filteredRows = rows;
      if (searchString) {
        filteredRows = rows.filter((row) =>
          colHeaders.some((header) => {
            const cellValue = row[header];
            return (
              cellValue && String(cellValue).toLowerCase().includes(searchString.toLowerCase())
            );
          }),
        );
      }
      const sorted = reapplySort(filteredRows, sortedColumn, sortDirection);
      setDisplayRows(sorted);
    }, [rows, searchString, sortedColumn, sortDirection, colHeaders, selectedRowIds]);

    const reapplySort = (rowsToSort: any[], sortCol: string | null, sortDir: "asc" | "desc") => {
      let sortedRows = rowsToSort;
      if (sortCol) {
        sortedRows = [...rowsToSort].sort((a, b) => {
          let aVal = a[sortCol],
            bVal = b[sortCol];
          if (customSortFunctions[sortCol]) {
            return customSortFunctions[sortCol](a, b, sortDir);
          }
          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortDir === "asc" ? aVal - bVal : bVal - aVal;
          }
          aVal = aVal ? String(aVal).toLowerCase() : "";
          bVal = bVal ? String(bVal).toLowerCase() : "";
          if (aVal < bVal) return sortDir === "asc" ? -1 : 1;
          if (aVal > bVal) return sortDir === "asc" ? 1 : -1;
          return 0;
        });
      }
      const lockedRows = nonDeselectableRowIds
        ? sortedRows.filter((row) => nonDeselectableRowIds.includes(row.id))
        : [];
      const nonLockedRows = nonDeselectableRowIds
        ? sortedRows.filter((row) => !nonDeselectableRowIds.includes(row.id))
        : sortedRows;
      const nonLockedSelected = nonLockedRows.filter((row) => selectedRowIds.includes(row.id));
      const nonLockedUnselected = nonLockedRows.filter((row) => !selectedRowIds.includes(row.id));
      return [...lockedRows, ...nonLockedSelected, ...nonLockedUnselected];
    };

    const handleSearch = (search: string) => {
      setSearchString(search);
    };

    const handleSort = (header: string) => {
      let newDir: "asc" | "desc" = "asc";
      if (sortedColumn === header) {
        newDir = sortDirection === "asc" ? "desc" : "asc";
      }
      setSortedColumn(header);
      setSortDirection(newDir);
      console.log(`Sorting by ${header} in ${newDir} order.`);
    };

    const handleRowCheckboxChange = (rowId: string | number) => {
      if (nonDeselectableRowIds && nonDeselectableRowIds.includes(rowId)) {
        if (!selectedRowIds.includes(rowId)) {
          onSelectionChange([...selectedRowIds, rowId]);
        }
        return;
      }
      let newSelection: (string | number)[];
      if (selectedRowIds.includes(rowId)) {
        newSelection = selectedRowIds.filter((id) => id !== rowId);
      } else {
        newSelection = [...selectedRowIds, rowId];
      }
      console.log("Row clicked. New selection state:", newSelection);
      onSelectionChange(newSelection);
    };

    const handleCheckAllChange = () => {
      let newSelection: (string | number)[];
      const selectableRows = displayRows.filter(
        (row) => !nonDeselectableRowIds || !nonDeselectableRowIds.includes(row.id),
      );
      const allSelectableSelected =
        selectableRows.length > 0 && selectableRows.every((row) => selectedRowIds.includes(row.id));
      if (allSelectableSelected) {
        newSelection = selectedRowIds.filter((id) =>
          displayRows.some(
            (row) => row.id === id && nonDeselectableRowIds && nonDeselectableRowIds.includes(id),
          ),
        );
      } else {
        const selectableIds = selectableRows.map((row) => row.id);
        newSelection = Array.from(new Set([...selectedRowIds, ...selectableIds]));
      }
      console.log("Check All clicked. New selection state:", newSelection);
      onSelectionChange(newSelection);
    };

    // When showCheckboxesForUnselected is false, only show rows that are selected or locked.
    const rowsToRender =
      showCheckboxesForUnselected || searchString
        ? displayRows
        : displayRows.filter(
            (row) =>
              selectedRowIds.includes(row.id) ||
              (nonDeselectableRowIds && nonDeselectableRowIds.includes(row.id)),
          );

    const selectableRows = displayRows.filter(
      (row) => !nonDeselectableRowIds || !nonDeselectableRowIds.includes(row.id),
    );

    // Common style for header cells.
    const headerCellStyle: React.CSSProperties = {
      padding: "0 8px",
    };

    // Render the header in its own table.
    const renderHeader = () => (
      <Table style={{ tableLayout: "fixed", width: "100%" }}>
        <TableHead>
          <TableRow style={{ height: headerHeight }}>
            {checkAll && (
              <TableCell
                style={{
                  ...headerCellStyle,
                  width: 50,
                  textAlign: "center",
                }}
              >
                {!searchString && (
                  <Checkbox
                    size="small"
                    color="default"
                    sx={{
                      color: "gray",
                      "&.Mui-checked": { color: "#00C889" },
                    }}
                    indeterminate={
                      selectableRows.length > 0 &&
                      !selectableRows.every((row) => selectedRowIds.includes(row.id)) &&
                      selectableRows.some((row) => selectedRowIds.includes(row.id))
                    }
                    checked={
                      selectableRows.length > 0 &&
                      selectableRows.every((row) => selectedRowIds.includes(row.id))
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCheckAllChange();
                    }}
                  />
                )}
              </TableCell>
            )}

            <TableCell
              style={{
                ...headerCellStyle,
                width: 150,
                textAlign: columnAlignments?.[colHeaders[0]] ?? "left",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>{colHeaders[0]}</span>
                {filterActivated && (
                  <IconButton size="small" onClick={() => handleSort(colHeaders[0])}>
                    {sortedColumn === colHeaders[0] ? (
                      sortDirection === "asc" ? (
                        <ChevronUpIcon className="w-4 h-4" style={{ stroke: "#00C889" }} />
                      ) : (
                        <ChevronDownIcon className="w-4 h-4" style={{ stroke: "#00C889" }} />
                      )
                    ) : (
                      <ChevronDownIcon
                        className="w-4 h-4"
                        style={{ stroke: "#00C889", opacity: 0.3 }}
                      />
                    )}
                  </IconButton>
                )}
              </div>
            </TableCell>
            <TableCell
              style={{
                ...headerCellStyle,
                width: "100%",
                textAlign: columnAlignments?.[colHeaders[1]] ?? "left",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: columnAlignments?.[colHeaders[1]] ?? "left",
                }}
              >
                <span>{colHeaders[1]}</span>
                {filterActivated && (
                  <IconButton size="small" onClick={() => handleSort(colHeaders[1])}>
                    {sortedColumn === colHeaders[1] ? (
                      sortDirection === "asc" ? (
                        <ChevronUpIcon className="w-4 h-4" style={{ stroke: "#00C889" }} />
                      ) : (
                        <ChevronDownIcon className="w-4 h-4" style={{ stroke: "#00C889" }} />
                      )
                    ) : (
                      <ChevronDownIcon
                        className="w-4 h-4"
                        style={{ stroke: "#00C889", opacity: 0.3 }}
                      />
                    )}
                  </IconButton>
                )}
              </div>
            </TableCell>
            <TableCell style={{ ...headerCellStyle, width: 150, position: "relative" }}>
              {showSearchBar && (
                <div style={{ position: "absolute", right: 10, bottom: 10 }}>
                  <TextField
                    value={searchString}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
                    placeholder={`${t("general.languages.search")}...`}
                    variant="outlined"
                    size="small"
                    sx={{
                      width: 220,
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon stroke="gray" strokeWidth={1.5} height={18} width={18} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    );

    // Render the table body in a scrollable div.
    const renderBody = () => (
      <div
        style={{ overflowY: "auto", maxHeight: maxTableHeightPx - headerHeight }}
        className="personalize-scroll-visible"
      >
        <Table style={{ tableLayout: "fixed", width: "100%" }}>
          <TableBody>
            {rowsToRender.length > 0 ? (
              rowsToRender.map((row, index) => (
                <TableRow
                  key={row.id || index}
                  hover
                  className="h-16"
                  style={{
                    backgroundColor:
                      nonDeselectableRowIds && nonDeselectableRowIds.includes(row.id)
                        ? "rgba(0,200,137,0.2)"
                        : undefined,
                  }}
                >
                  {checkAll && (
                    <TableCell
                      style={{
                        width: 50,
                        padding:
                          nonDeselectableRowIds && nonDeselectableRowIds.includes(row.id)
                            ? "0 11px"
                            : "0 8px",
                        textAlign: "center",
                      }}
                    >
                      {nonDeselectableRowIds && nonDeselectableRowIds.includes(row.id) ? (
                        <Checkbox
                          size="small"
                          color="default"
                          checked
                          disabled
                          icon={<LockClosedIcon className="w-4 h-4" />}
                          checkedIcon={<LockClosedIcon className="w-4 h-4" />}
                        />
                      ) : (
                        <Checkbox
                          size="small"
                          color="default"
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "#00C889" },
                          }}
                          checked={selectedRowIds.includes(row.id)}
                          onClick={(e) => {
                            e.stopPropagation();
                            console.log("Checkbox clicked (onClick) for row id:", row.id);
                            handleRowCheckboxChange(row.id);
                          }}
                        />
                      )}
                    </TableCell>
                  )}
                  <TableCell
                    style={{
                      width: 150,
                      padding: "0 8px",
                      textAlign: columnAlignments?.[colHeaders[0]] ?? "left",
                    }}
                    className={cellClassName}
                  >
                    {customCellRenderers && customCellRenderers[colHeaders[0]]
                      ? customCellRenderers[colHeaders[0]](row)
                      : row[colHeaders[0]]}
                  </TableCell>
                  <TableCell
                    style={{
                      width: "100%",
                      padding: "0 8px",
                      textAlign: columnAlignments?.[colHeaders[1]] ?? "left",
                    }}
                    className={cellClassName}
                  >
                    {customCellRenderers && customCellRenderers[colHeaders[1]]
                      ? customCellRenderers[colHeaders[1]](row)
                      : row[colHeaders[1]]}
                  </TableCell>
                  <TableCell style={{ width: 150, padding: "0 8px", textAlign: "right" }}>
                    {nonDeselectableRowIds && nonDeselectableRowIds.includes(row.id) ? (
                      <LanguageIcon
                        height={20}
                        width={20}
                        stroke="rgb(0 200 137)"
                        style={{ marginLeft: "3rem" }}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={checkAll ? 4 : 3} align="center">
                  {emptyPlaceholder}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );

    return (
      <TableContainer
        component={Paper}
        ref={ref}
        className={className}
        style={{ maxHeight: maxTableHeightPx, overflow: "hidden" }}
      >
        {renderHeader()}
        {renderBody()}
      </TableContainer>
    );
  },
);

NotSoModernGenericTable.displayName = "NotSoModernGenericTable";

export default NotSoModernGenericTable;
