import { ReactElement, HTMLAttributes } from "react";

interface IProgressWithValues extends HTMLAttributes<HTMLDivElement> {
  current: number;
  total: number;
  wrapperClassName?: string;
  barClassName?: string;
  animated?: boolean;
  labelClassName?: string;
}

export function ProgressBarWithValues({
  current,
  total,
  wrapperClassName = "",
  barClassName = "",
  animated = false,
  labelClassName = "",
  className,
  ...rest
}: IProgressWithValues): ReactElement {
  const percentage = total > 0 ? Math.round((current / total) * 100) : 0;
  const isEmpty = percentage === 0;
  const isFull = percentage === 100;
  const fillBg = isEmpty ? "bg-transparent" : isFull ? "bg-[#00C889]" : "bg-[#FFC107]";
  const textColor = isEmpty ? "text-black" : isFull ? "text-[#00C889]" : "text-[#FFC107]";

  return (
    <div {...rest} className={`${className} ${wrapperClassName} inline-block`}>
      <div className="relative w-full">
        <div className={`bg-gray-300 rounded-full overflow-hidden h-3 ${barClassName}`}>
          <div
            style={{ width: `${percentage}%` }}
            className={`h-full rounded-full ${fillBg} ${
              animated ? "transition-all duration-500 ease-in-out" : ""
            }`}
          ></div>
        </div>
        <span
          className={`absolute left-full ml-2 top-1/2 transform -translate-y-1/2 text-[0.6rem] ${textColor} ${labelClassName}`}
        >
          {percentage}%
        </span>
      </div>
      <div className={`w-full text-center text-xs mt-1 ${textColor} ${labelClassName}`}>
        {current} / {total}
      </div>
    </div>
  );
}
