export const baseUrl = process.env.REACT_APP_API_ENDPOINT;
/* We removed the ternary to resolve deployment issues.
You now need to pass the api endpoint manually as an environment variable.

Feel free to copy/paste the line below in your terminal :

REACT_APP_API_ENDPOINT="http://15.188.66.181:1337" npm run start

REACT_APP_API_ENDPOINT="https://apipreprod.wixar.io" npm run start
*/
const apiUrls = {
  base: baseUrl,
  getWixarLauncherUris: `${baseUrl}/api/custom/getWixarLauncherUrls`,
  getEditorWebLastCommit: `${baseUrl}/custom/getLastEditorWebCommitHash`,
  readyPlayerMe: "https://render.readyplayer.me/render",
  readyPlayerMeAvatarPreview: "https://models.readyplayer.me",
  sseStream: `${baseUrl}/api/event/sse`,
  register: {
    user: `${baseUrl}/auth/local/register`,
    organization: `${baseUrl}/api/organizations`,
    users: `${baseUrl}/api/users`,
    endusers: `${baseUrl}/custom/endusersPerOrga`,
    deleteCreator: `${baseUrl}/api/users-permissions/custom/deleteCreator`,
    deleteCreators: `${baseUrl}/api/users-permissions/custom/deleteCreators`,
    deleteEnduser: `${baseUrl}/api/users-permissions/custom/deleteEnduser`,
    deleteEndusers: `${baseUrl}/api/users-permissions/custom/deleteEndusers`,
  },
  auth: {
    signIn: `${baseUrl}/api/auth/creator`,
    signInWithToken: `${baseUrl}/auth/creatorWithToken`,
    checkPwd: `${baseUrl}/api/users-permissions/auth/checkPwd`,
    checkEmail: `${baseUrl}/api/users/checkemail`,
    forgetPassword: `${baseUrl}/api/auth/forgot-password`,
    resetPassword: `${baseUrl}/api/auth/reset-password`,
    changePasswordFromProfile: `${baseUrl}/api/auth/change-password`,
    generateVrStudioJwt: `${baseUrl}/api/auth/convertJwt`,
  },
  contents: `${baseUrl}/api/contents`,
  builds: `${baseUrl}/api/builds`,
  themeInfos: `${baseUrl}/api/theme-infos`,
  languages: `${baseUrl}/api/languages`,
  projects: {
    projectTags: `${baseUrl}/api/project-tags`,
    all: `${baseUrl}/api/projects`,
    translateProject: `${baseUrl}/api/projects/translations`,
    multipleProjects: `${baseUrl}/api/custom/project/multiple`,
    askPublication: `${baseUrl}/api/custom/askPublication`,
    retryPublication: `${baseUrl}/api/custom/retryPublication`,
    allWithUserTemplates: `${baseUrl}/api/custom/projectsWithUserTemplates`,
    clone: `${baseUrl}/custom/clone`,
    duplicateProject: `${baseUrl}/api/custom/duplicateProject`,
    cloneTemplate: `${baseUrl}/custom/makeTemplate`,
    editSceneType: `${baseUrl}/custom/editSceneType`,
    alterProjectStyle: `${baseUrl}/custom/alterProjectStyle`,
    templates: `${baseUrl}/api/template-overviews`,
    createScene: `${baseUrl}/api/custom/createScene`,
    contens: {
      contentScene: `${baseUrl}/content-scenes`,
      contentType: `${baseUrl}/content-types`,
    },
    getMetaDatas: `${baseUrl}/api/projects/metadata`,
    deleteNode: `${baseUrl}/api/projects/deleteNode`,
    graph: {
      sessions: `${baseUrl}/sessions`,
      flat: `${baseUrl}/api/projects/full`,
    },
    projectLanguageDatas: `${baseUrl}/api/project-language-data`,
    projectUserPermissions: `${baseUrl}/api/project-user-permissions`,
    organizationPermissions: `${baseUrl}/custom/statsperorg`,
    inviteExternalUser: `${baseUrl}/custom/inviteExternalUser`,
    inviteEnduser: `${baseUrl}/api/users-permissions/custom/inviteEnduser`,
    inviteEnduserWithoutProject: `${baseUrl}/custom/inviteEnduserWithoutProject`,
    bulkInviteEndusersWithoutProject: `${baseUrl}/custom/bulkInviteEndusersWithoutProject`,
    inviteExternalCreatorToOrg: `${baseUrl}/api/users-permissions/custom/inviteExternalCreatorToOrg`,
    inviteExistingUser: `${baseUrl}/api/users-permissions/custom/invite`,
    getEnduser: `${baseUrl}/custom/endusersPerProject`,
    getEnduserStat: `${baseUrl}/custom/enduserStatsPerProject`,
    getEnduserProjects: `${baseUrl}/custom/getEnduserPlayedProject`,
    sendResults: `${baseUrl}/custom/sendResults`,
    nuSendResults: `${baseUrl}/custom/nuSendResults`,
    sendGlobalResults: `${baseUrl}/custom/sendGlobalResults`,
    nuSendGlobalResults: `${baseUrl}/custom/nuSendGlobalResults`,
    notifyEnduserWaitingForPublish: `${baseUrl}/custom/notifyEnduserWaitingForPublish`,
    getContentScoringGeneralCount: `${baseUrl}/custom/getContentScoringGeneralCount`,
    projectGroups: `${baseUrl}/api/project-groups`,
    multipleProjectGroups: `${baseUrl}/api/project-groups/multiple`,
    projectGroupElements: `${baseUrl}/api/project-group-elements`,
    projectsAndGroups: `${baseUrl}/projectsGroupWithProjects`,
    projectGroupsWithProjects: `${baseUrl}/api/custom/projectGroupsWithProjects`,
    makeTemplate: `${baseUrl}/custom/makeTemplate`,
    reorderProjects: `${baseUrl}/api/custom/reorderProjects`,
    projectSize: `${baseUrl}/api/getSizeOf/project`,
    projectGroupSize: `${baseUrl}/api/getSizeOf/projectGroup`,
    projectTranslations: `${baseUrl}/api/projects/translations`,
  },
  medias: {
    all: `${baseUrl}/api/source-medias`,
    create: `${baseUrl}/upload`,
    editmedia: `${baseUrl}/editmedia`,
    generateAudio: `${baseUrl}/api/custom/tts`,
    createEmptyFile: `${baseUrl}/custom/upload/initialize`,
    initializeMultipart: `${baseUrl}/api/custom/upload/initializeMultipart`,
    askForPublicationOf3DEnvironment: `${baseUrl}/api/custom/upload/askForPublicationOf3DEnvironment`,
    getSignedPartUploadLink: `${baseUrl}/api/custom/upload/getSignedPartUploadLink`,
    completeMultipartUpload: `${baseUrl}/api/custom/upload/completeMultipartUpload`,
    addMediaToDatabase: `${baseUrl}/api/custom/upload/addMediaToDatabase`,
    addTagstoDatabase: `${baseUrl}/api/custom/tags/addTagsToDatabase`,
    getTagsOrganization: `${baseUrl}/api/tags`,
    checkIfMediasAreUsed: `${baseUrl}/api/custom/checkExistAll`,
    deleteMultipleMedias: `${baseUrl}/api/custom/deleteMultipleMedia`,
    blockadeLabs: {
      models: `${baseUrl}/api/blockadeLabs/models`,
      generate: `${baseUrl}/api/blockadeLabs/generate`,
      addBlockadeImageToDatabase: `${baseUrl}/api/custom/upload/addBlockadeImageToDatabase`,
    },
    elevenLabs: {
      fetchVoices: `${baseUrl}/api/custom/ttsVoices`,
      generateAudio: `${baseUrl}/api/custom/tts`,
    },
  },
  csv: {
    add: `${baseUrl}/api/users-permissions/custom/addEndusersCsv`,
  },
  styleTemplates: {
    all: `${baseUrl}/style-templates`,
  },
  creators: `${baseUrl}/api/users?role=5`,
  endusers: `${baseUrl}/api/users?role=4`,
  teamForAdmin: `${baseUrl}/api/users-permissions/custom/getOrgMembersWithPermissions`,
  readspeaker: "https://tts.readspeaker.com/a/speak",
  mail: `${baseUrl}/custom/mail`,
  creatorWantPublish: `${baseUrl}/custom/creatorWantPublish`,
  getMostRecentEditorVrBuildPerPlatform: `${baseUrl}/custom/getMostRecentEditorVrBuildPerPlatform`,
  getLatestEditorVrBuild: `${baseUrl}/custom/getLatestEditorVrBuild`,
  paEditLanguage: `${baseUrl}/custom/paEditCustomerLanguage`,
  userErrorTraces: `${baseUrl}/api/user-error-traces`,
  inviteWithoutEmail: `${baseUrl}/api/users-permissions/custom/inviteSimplifiedEnduser`,
  inviteWithCsv: `${baseUrl}/custom/inviteEnduserCsv`,
  stats: {
    getAllSessionsByProject: `${baseUrl}/api/scores/projectsSessions`,
    getAllSessionsByProjectGroup: `${baseUrl}/api/scores/projectGroupsSessions`,
    successRatePerScene: `${baseUrl}/api/scores/successRatePerScene`,
    successRatePerProjectGroup: `${baseUrl}/api/scores/averageSuccessRatePerProjectGroup`,
    enduserStatsPerProject: `${baseUrl}/api/scores/enduserStatsPerProject`,
    sendProjectResults: `${baseUrl}/custom/nuSendResults`,
    sendGlobalResults: `${baseUrl}/api/scores/sendGlobalResults`,
    getGlobalResultsDownloadLink: `${baseUrl}/api/scores/globalResultsDownloadLink`,
    enduserProjectGroupsCompletionSortedByLatestSession: `${baseUrl}/api/scores/enduserProjectGroupsCompletionSortedByLatestSession`,
    enduserSessionDetailsForGivenProject: `${baseUrl}/api/scores/enduserSessionDetailsForGivenProject`,
    enduserProjectGroupCompletion: `${baseUrl}/api/scores/enduserProjectGroupCompletion`,
    enduserStatsOverview: `${baseUrl}/api/scores/enduserStatsOverview`,
  },
  admin: {
    userDetails: `${baseUrl}/api/users-permissions/custom/userDetails`,
    inviteCreatorWithPerms: `${baseUrl}/api/users-permissions/custom/inviteCreatorWithPerms`,
    inviteEnduserWithPerms: `${baseUrl}/api/users-permissions/custom/inviteEnduserWithPerms`,
    editCreatorPerms: `${baseUrl}/api/users-permissions/custom/editCreatorPerms`,
    editEnduserPerms: `${baseUrl}/api/users-permissions/custom/editEnduserPerms`,
  },
  paEmbedLinks: `${baseUrl}/api/billing/paEmbedLinks`,
  anonymousSessionsCount: `${baseUrl}/api/billing/anonymousSessionsCount`,
  getSubscriptionState: `${baseUrl}/api/subscriptionFeatures/getSubscriptionState`,
  userOpenedTutorial: `${baseUrl}/api/user-opened-tutorials`,
  userOpenedTutorialReset: `${baseUrl}/api/user-opened-tutorials/reset`,
  moodle: {
    moodleEnv: `${baseUrl}/api/moodle-envs`,
    generate: `${baseUrl}/api/moodle/generate`,
    revoke: `${baseUrl}/api/moodle/revoke`,
  },
  gpt: {
    createChat: `${baseUrl}/api/gpt/createChat`,
    updateChat: `${baseUrl}/api/gpt/updateChat`,
    resetChat: `${baseUrl}/api/gpt/resetChat`,
    askWixarpedia: `${baseUrl}/api/gpt/askWixarpedia`,
    gptConversations: `${baseUrl}/api/gpt-conversations`,
    generateTemplate: `${baseUrl}/api/gpt/generateTemplate`,
  },
};

export default apiUrls;
