import React, { useState, useEffect, forwardRef, ChangeEvent } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Checkbox,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  LockClosedIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { TextFieldProps } from "@mui/material/TextField";

/**
 * Props for ModernGenericTable.
 *
 * @typedef {object} ModernGenericTableProps
 * @property {any[]} rows - Data rows to render.
 * @property {string[]} colHeaders - Column header labels.
 * @property {((selectedRowIds: (string|number)[]) => void)=} onSelectionChange
 *   Callback fired when row selection changes.
 * @property {Object.<string, (a:any, b:any, direction:"asc"|"desc")=>number>=} customSortFunctions
 *   Optional custom sort functions for specific columns.
 * @property {(string|number)[]} selectedRowIds - Currently selected row IDs.
 * @property {(string|number)[]=} nonDeselectableRowIds
 *   Row IDs that remain selected and cannot be deselected.
 * @property {Object.<string, (row:any)=>React.ReactNode>=} customCellRenderers
 *   Custom renderers for cell content, keyed by column header.
 * @property {Object.<string, "left"|"center"|"right">=} columnAlignments
 *   Text alignment for columns, keyed by header.
 * @property {(number|string)=} columnProportions
 *   Mapping of column header to a custom width value (e.g. "20%", "150px", or a number).
 *   When specified, these values override the default width calculations.
 * @property {Partial<TextFieldProps>=} searchBarProps
 *   Optional props to customize the search bar TextField in the header.
 * @property {Object.<string, string>=} headerCellClasses
 *   Mapping of column header to additional class names for header cell content.
 */
export interface ModernGenericTableProps {
  maxTableHeightPx?: number;
  rows: any[];
  colHeaders: string[];
  className?: string;
  cellClassName?: string;
  emptyPlaceholder?: React.ReactNode;
  checkAll?: boolean;
  showSearchBar?: boolean;
  filterActivated?: boolean;
  onSelectionChange?: (selectedRowIds: (string | number)[]) => void;
  customSortFunctions?: {
    [key: string]: (a: any, b: any, direction: "asc" | "desc") => number;
  };
  selectedRowIds: (string | number)[];
  nonDeselectableRowIds?: (string | number)[];
  customCellRenderers?: {
    [columnHeader: string]: (row: any) => React.ReactNode;
  };
  columnAlignments?: { [columnHeader: string]: "left" | "center" | "right" };
  showCheckboxesForUnselected?: boolean;
  columnProportions?: { [columnHeader: string]: number | string };
  searchBarProps?: Partial<TextFieldProps>;
  headerCellClasses?: { [columnHeader: string]: string };
}

/**
 * ModernGenericTable renders a table with search, sorting, and row selection.
 *
 * @param {ModernGenericTableProps} props - Component props.
 * @returns {JSX.Element}
 */
const ModernGenericTable = forwardRef<HTMLDivElement, ModernGenericTableProps>(
  (
    {
      maxTableHeightPx = 0,
      rows,
      colHeaders,
      className = "",
      cellClassName = "",
      emptyPlaceholder = "No data available",
      checkAll = false,
      showSearchBar = false,
      filterActivated = true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSelectionChange = () => {},
      customSortFunctions = {},
      selectedRowIds,
      nonDeselectableRowIds,
      customCellRenderers,
      columnAlignments,
      showCheckboxesForUnselected = true,
      columnProportions,
      searchBarProps,
      headerCellClasses,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [searchString, setSearchString] = useState("");
    const [sortedColumn, setSortedColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [displayRows, setDisplayRows] = useState<any[]>(rows);

    const headerHeight = 56;

    useEffect(() => {
      let filteredRows = rows;
      if (searchString) {
        filteredRows = rows.filter((row) =>
          colHeaders.some((header) => {
            const cellValue = row[header];
            return (
              cellValue && String(cellValue).toLowerCase().includes(searchString.toLowerCase())
            );
          }),
        );
      }
      const sorted = reapplySort(filteredRows, sortedColumn, sortDirection);
      setDisplayRows(sorted);
    }, [rows, searchString, sortedColumn, sortDirection, colHeaders, selectedRowIds]);

    const reapplySort = (rowsToSort: any[], sortCol: string | null, sortDir: "asc" | "desc") => {
      let sortedRows = rowsToSort;
      if (sortCol) {
        sortedRows = [...rowsToSort].sort((a, b) => {
          let aVal = a[sortCol],
            bVal = b[sortCol];
          if (customSortFunctions[sortCol]) {
            return customSortFunctions[sortCol](a, b, sortDir);
          }
          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortDir === "asc" ? aVal - bVal : bVal - aVal;
          }
          aVal = aVal ? String(aVal).toLowerCase() : "";
          bVal = bVal ? String(bVal).toLowerCase() : "";
          if (aVal < bVal) return sortDir === "asc" ? -1 : 1;
          if (aVal > bVal) return sortDir === "asc" ? 1 : -1;
          return 0;
        });
      }
      return sortedRows;
    };

    const handleSearch = (search: string) => {
      setSearchString(search);
    };

    const handleSort = (header: string) => {
      let newDir: "asc" | "desc" = "asc";
      if (sortedColumn === header) {
        newDir = sortDirection === "asc" ? "desc" : "asc";
      }
      setSortedColumn(header);
      setSortDirection(newDir);
      console.log(`Sorting by ${header} in ${newDir} order.`);
    };

    const handleRowCheckboxChange = (rowId: string | number) => {
      if (nonDeselectableRowIds && nonDeselectableRowIds.includes(rowId)) {
        if (!selectedRowIds.includes(rowId)) {
          onSelectionChange([...selectedRowIds, rowId]);
        }
        return;
      }
      let newSelection: (string | number)[];
      if (selectedRowIds.includes(rowId)) {
        newSelection = selectedRowIds.filter((id) => id !== rowId);
      } else {
        newSelection = [...selectedRowIds, rowId];
      }
      console.log("Row clicked. New selection state:", newSelection);
      onSelectionChange(newSelection);
    };

    const handleCheckAllChange = () => {
      let newSelection: (string | number)[];
      const selectableRows = displayRows.filter(
        (row) => !nonDeselectableRowIds || !nonDeselectableRowIds.includes(row.id),
      );
      const allSelectableSelected =
        selectableRows.length > 0 && selectableRows.every((row) => selectedRowIds.includes(row.id));
      if (allSelectableSelected) {
        newSelection = selectedRowIds.filter((id) =>
          displayRows.some(
            (row) => row.id === id && nonDeselectableRowIds && nonDeselectableRowIds.includes(id),
          ),
        );
      } else {
        const selectableIds = selectableRows.map((row) => row.id);
        newSelection = Array.from(new Set([...selectedRowIds, ...selectableIds]));
      }
      console.log("Check All clicked. New selection state:", newSelection);
      onSelectionChange(newSelection);
    };

    const rowsToRender = showCheckboxesForUnselected
      ? displayRows
      : displayRows.filter(
          (row) =>
            selectedRowIds.includes(row.id) ||
            (nonDeselectableRowIds && nonDeselectableRowIds.includes(row.id)),
        );

    const getColumnWidth = (
      header: string,
      index: number,
      totalColumns: number,
    ): number | string => {
      if (columnProportions && columnProportions[header] !== undefined) {
        return columnProportions[header];
      }
      // If header is empty and search bar is shown, set specific width
      if (header === "" && showSearchBar) {
        return 220;
      }
      // Set first and last columns to a fixed width (e.g., 150px)
      if (index === 0 || index === totalColumns - 1) {
        return 150;
      }
      return "auto";
    };

    const renderHeader = () => (
      <Table style={{ tableLayout: "fixed", width: "100%" }}>
        <colgroup>
          {checkAll && <col style={{ width: 50 }} />}
          {colHeaders.map((header, index) => (
            <col key={index} style={{ width: getColumnWidth(header, index, colHeaders.length) }} />
          ))}
        </colgroup>
        <TableHead>
          <TableRow style={{ height: headerHeight }}>
            {checkAll && (
              <TableCell style={{ padding: "0 8px", width: 50, textAlign: "center" }}>
                <Checkbox
                  size="small"
                  color="default"
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "#00C889" },
                  }}
                  indeterminate={
                    rowsToRender.length > 0 &&
                    !rowsToRender.every((row) => selectedRowIds.includes(row.id))
                  }
                  checked={
                    rowsToRender.length > 0 &&
                    rowsToRender.every((row) => selectedRowIds.includes(row.id))
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckAllChange();
                  }}
                />
              </TableCell>
            )}
            {colHeaders.map((header, index) => (
              <TableCell
                key={index}
                style={{
                  padding: "0 8px",
                  width: getColumnWidth(header, index, colHeaders.length),
                  textAlign: columnAlignments?.[header] ?? "left",
                }}
              >
                {header === "" && showSearchBar ? (
                  <div className="w-full">
                    <TextField
                      {...(searchBarProps || {})}
                      value={searchString}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
                      placeholder={`${t("general.languages.search")}...`}
                      variant="outlined"
                      size="small"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                        ...(searchBarProps?.sx || {}),
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon stroke="gray" strokeWidth={1.5} height={18} width={18} />
                          </InputAdornment>
                        ),
                        ...(searchBarProps?.InputProps || {}),
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <span
                      className={
                        headerCellClasses && headerCellClasses[header]
                          ? headerCellClasses[header]
                          : ""
                      }
                    >
                      {header}
                    </span>
                    {filterActivated && header && (
                      <IconButton size="small" onClick={() => handleSort(header)}>
                        {sortedColumn === header ? (
                          sortDirection === "asc" ? (
                            <ChevronUpIcon className="w-4 h-4" style={{ stroke: "#00C889" }} />
                          ) : (
                            <ChevronDownIcon className="w-4 h-4" style={{ stroke: "#00C889" }} />
                          )
                        ) : (
                          <ChevronDownIcon
                            className="w-4 h-4"
                            style={{ stroke: "#00C889", opacity: 0.3 }}
                          />
                        )}
                      </IconButton>
                    )}
                  </div>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    );

    const renderBody = () => (
      <div
        style={{ overflowY: "auto", maxHeight: maxTableHeightPx - headerHeight }}
        className="personalize-scroll-visible"
      >
        <Table style={{ tableLayout: "fixed", width: "100%" }}>
          <colgroup>
            {checkAll && <col style={{ width: 50 }} />}
            {colHeaders.map((header, index) => (
              <col
                key={index}
                style={{ width: getColumnWidth(header, index, colHeaders.length) }}
              />
            ))}
          </colgroup>
          <TableBody>
            {rowsToRender.length > 0 ? (
              rowsToRender.map((row, index) => (
                <TableRow
                  key={row.id || index}
                  hover
                  className="h-16"
                  style={{
                    backgroundColor:
                      nonDeselectableRowIds && nonDeselectableRowIds.includes(row.id)
                        ? "rgba(0,200,137,0.2)"
                        : undefined,
                  }}
                >
                  {checkAll && (
                    <TableCell
                      style={{
                        width: 50,
                        padding: "0 8px",
                        textAlign: "center",
                      }}
                    >
                      {nonDeselectableRowIds && nonDeselectableRowIds.includes(row.id) ? (
                        <Checkbox
                          size="small"
                          color="default"
                          checked
                          disabled
                          icon={<LockClosedIcon className="w-4 h-4" />}
                          checkedIcon={<LockClosedIcon className="w-4 h-4" />}
                        />
                      ) : (
                        <Checkbox
                          size="small"
                          color="default"
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "#00C889" },
                          }}
                          checked={selectedRowIds.includes(row.id)}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowCheckboxChange(row.id);
                          }}
                        />
                      )}
                    </TableCell>
                  )}
                  {colHeaders.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{
                        padding: "0 8px",
                        width: getColumnWidth(header, index, colHeaders.length),
                        textAlign: columnAlignments?.[header] ?? "left",
                      }}
                      className={cellClassName}
                    >
                      {customCellRenderers && customCellRenderers[header]
                        ? customCellRenderers[header](row)
                        : row[header]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={checkAll ? colHeaders.length + 1 : colHeaders.length}
                  align="center"
                >
                  {emptyPlaceholder}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );

    return (
      <TableContainer
        component={Paper}
        ref={ref}
        className={className}
        style={{ maxHeight: maxTableHeightPx, overflow: "hidden" }}
      >
        {renderHeader()}
        {renderBody()}
      </TableContainer>
    );
  },
);

ModernGenericTable.displayName = "ModernGenericTable";
export default ModernGenericTable;
