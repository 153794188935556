import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

interface AutoTranslateCheckboxProps {
  isSelected: boolean;
  onToggle: () => void;
  disabled?: boolean;
  tooltip?: string;
}

const AutoTranslateCheckbox: React.FC<AutoTranslateCheckboxProps> = ({
  isSelected,
  onToggle,
  disabled = false,
  tooltip = "",
}) => {
  const { t } = useTranslation();

  const containerClasses = `auto-translate-toggle inline-flex items-center justify-center ${
    disabled
      ? "cursor-not-allowed opacity-50 bg-gray-100 border-gray-400"
      : "cursor-pointer border-green-500"
  } pt-2 pb-2 pr-4 pl-4 rounded-lg border-[0.1rem]`;

  const content = (
    <div className={containerClasses} onClick={!disabled ? onToggle : undefined}>
      <input type="checkbox" checked={isSelected} readOnly disabled={disabled} className="mr-2" />
      <span className={`select-none ${disabled ? "text-gray-500" : ""}`}>
        {t("pages.editProjectLanguage.autoTranslation")}
      </span>
    </div>
  );

  return disabled ? (
    <Tooltip title={tooltip}>
      <div>{content}</div>
    </Tooltip>
  ) : (
    content
  );
};

export default AutoTranslateCheckbox;
