import { XIcon } from "@heroicons/react/outline";
import React, { useEffect } from "react";

interface SimpleModalProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
}

const SimpleModal: React.FC<SimpleModalProps> = ({ isOpen, onClose, children, className = "" }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-25" onClick={onClose}></div>
      <div className={`relative bg-slate-100 rounded-xl shadow-2xl p-4 ${className}`}>
        <button
          className="absolute top-5 right-5 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <XIcon className="h-6 w-6" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default SimpleModal;
