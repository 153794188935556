import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../state/hooks";
import { RootState } from "../../state/store";
import {
  closeConfirmationModal,
  closeMediaPreviewModal,
  closeStandardModal,
} from "../../state/slice/modal/modalSlice";
import { XIcon } from "@heroicons/react/outline";
import { isStringNullOrEmpty } from "../../util/isStringNullOrEmpty";
import { useTranslation } from "react-i18next";
import { getCurrentUploadsStateList } from "../../../features/gallery/state/gallerySlice";

type ModalStandardProps = {
  children?: React.ReactNode;
  fullScreen?: boolean;
  id: string;
  modalType?: "standard" | "mediaPreview" | "confirmation" | "navigateOut";
  isStatic?: boolean;
  isOpen?: boolean;
  className?: string;
  showCloseButton?: boolean;
  showSaveButton?: boolean;
  onClose?: () => void;
  clickOnBackgroundClose?: boolean;
  minimize?: boolean;
  controlledMode?: { isOpen: boolean };
  overFlowOff?: boolean;
};

const ModalStandard = ({
  id,
  children,
  fullScreen,
  modalType = "standard",
  isStatic = false,
  className = "",
  showCloseButton = false,
  showSaveButton = false,
  clickOnBackgroundClose = true,
  minimize = false,
  controlledMode = undefined,
  overFlowOff = false,
  onClose,
}: ModalStandardProps) => {
  const dispatch = useAppDispatch();
  const modalState = useSelector((state: RootState) => {
    switch (modalType) {
      case "confirmation":
        return state.modals.showConfirmationModal;
      case "mediaPreview":
        return state.modals.showMediaPreviewModal;
      case "standard":
      default:
        return state.modals.showStandardModal;
    }
  });

  const [isModalOpen, setIsModalOpen] = useState(controlledMode ? controlledMode.isOpen : false);

  useEffect(() => {
    if (controlledMode) {
      setIsModalOpen(controlledMode.isOpen);
    } else {
      if (modalState.id === id || (!modalState.id && !modalState.show)) {
        setIsModalOpen(modalState.show);
      }
    }
  }, [modalState]);

  const { t } = useTranslation();

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
    switch (modalType) {
      case "confirmation":
        dispatch(closeConfirmationModal(id));
        break;
      case "mediaPreview":
        dispatch(closeMediaPreviewModal(id));
        break;
      case "standard":
      default:
        dispatch(closeStandardModal(id));
    }
  };
  const currentUploadsStateList = useSelector(getCurrentUploadsStateList);

  return (
    <>
      {isModalOpen && (
        <Transition appear show={isModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className={`relative z-10 ${
              overFlowOff ? "" : "overflow-y-auto personalize-scroll-visible "
            }`}
            onClose={() => (clickOnBackgroundClose ? closeModal() : null)}
            static={isStatic}
            onClick={(e: any) => e.stopPropagation()}
          >
            {!fullScreen && (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className={
                    "fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center h-full w-full"
                  }
                />
              </Transition.Child>
            )}
            <div
              style={
                fullScreen
                  ? {
                      height: currentUploadsStateList.length > 0 ? "calc(100% - 4rem)" : "100%",
                    }
                  : {}
              }
              className={`fixed ${fullScreen ? "top-0 left-[8rem] bottom-0 right-0" : "inset-0"}`}
            >
              <div
                className={
                  fullScreen ? "w-full" : "flex items-center justify-center p-4 text-center h-full"
                }
              >
                <div
                  className={
                    !fullScreen
                      ? `mt-auto mb-auto flex w-full justify-center items-center ${
                          minimize ? "" : "h-full"
                        }`
                      : ""
                  }
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel
                      className={`modal-standard h-full transform ${
                        fullScreen
                          ? `${
                              isStringNullOrEmpty(className)
                                ? "w-[calc(100%-8px)] max-w-[calc(100%-32px)]"
                                : ""
                            } modal-fullscreen absolute top-0 right-0`
                          : `${
                              isStringNullOrEmpty(className) ? "w-2/3 h-fit" : ""
                            } rounded-2xl pt-auto pb-auto shadow-xl`
                      } ${
                        overFlowOff ? "" : "overflow-y-auto personalize-scroll-visible"
                      } bg-slate-100 ${
                        !fullScreen && "p-6"
                      } text-left align-middle transition-all ${className}`}
                    >
                      {showCloseButton && (
                        <button
                          type="button"
                          className="z-50 fixed top-4 right-4"
                          onClick={closeModal}
                          style={{ position: "absolute", top: "1rem", right: "1rem" }}
                        >
                          <span className="sr-only">{t("general.cancel")}</span>
                          <XIcon className="h-6 w-6 stroke-black" aria-hidden="true" />
                        </button>
                      )}
                      {children}
                      {showSaveButton && (
                        <div className="flex justify-end mt-3">
                          <button
                            onClick={closeModal}
                            className="btn-primary-fill mt-5"
                            type="submit"
                          >
                            {t("general.confirm")}
                          </button>
                        </div>
                      )}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default ModalStandard;
