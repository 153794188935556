import { useSelector } from "react-redux";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import ModalStandard from "src/common/components/Modal/ModalStandard";
import { getCurrentProjectTranslationState } from "src/features/projects/state/projectsSlice";
import { DISPLAY_PROJECT_TRANSLATION_STATE_COMPONENT } from "./ProjectLanguageSelectorWrapper";
import { useTranslation } from "react-i18next";
import ModernAutoTranslationSelector from "./ModernAutoTranslationSelector";
import { useState } from "react";

const ProjectAutomaticTranslationModal = () => {
  const currentProjectTranslationState = useSelector(getCurrentProjectTranslationState);

  const [languageIdsValue, setLanguageIdsValue] = useState<string[]>([]);

  const tableRowsProperlyMapped = currentProjectTranslationState.map((cpts) => {
    return cpts;
  });

  const { t } = useTranslation();
  return (
    <ModalStandard
      id="projectAutomaticTranslation"
      className="w-2/3 h-fit"
      fullScreen={false}
      showCloseButton
    >
      <IsLoading
        componentId={DISPLAY_PROJECT_TRANSLATION_STATE_COMPONENT}
        spinnerPlaceholder
        showSuccess={false}
      >
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex flex-col items-center justify-center">
            <p className="text-lg font-bold mb-4">
              {t("pages.editProjectLanguage.selectLanguageYouWantToTranslateAutomatically")}
            </p>
          </div>
          <ModernAutoTranslationSelector
            rows={tableRowsProperlyMapped}
            sourceLanguageId={
              currentProjectTranslationState.find((pst) => pst.isSourceLanguage)
                ? String(
                    currentProjectTranslationState.find((pst) => pst.isSourceLanguage).languageId,
                  )
                : "-1"
            }
            selectedLanguages={languageIdsValue}
            setSelectedLanguages={(selected: string[]) => {
              setLanguageIdsValue(selected);
            }}
          />
        </div>
      </IsLoading>
    </ModalStandard>
  );
};

export default ProjectAutomaticTranslationModal;
