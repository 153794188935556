import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../common/state/store";

interface IGraphuState {
  isValid: boolean;
  isLoading: boolean;
  shouldRerender: boolean;
}
const initialState: IGraphuState = {
  isValid: false,
  isLoading: false,
  shouldRerender: false,
};

export const graphuSlice = createSlice({
  name: "graphu",
  initialState,
  reducers: {
    setIsValid: (state: IGraphuState, action: any) => {
      state.isValid = action.payload;
    },
    setIsLoading: (state: IGraphuState, action: any) => {
      state.isLoading = action.payload;
    },
    setShouldRerender: (state: IGraphuState) => {
      state.shouldRerender = !state.shouldRerender;
    },
  },
});

export const { setIsValid, setIsLoading, setShouldRerender } = graphuSlice.actions;

export const getIsValid = (state: RootState) => state.graphu.isValid;
export const getIsLoading = (state: RootState) => state.graphu.isLoading;
export const getShouldRerenderGraph = (state: RootState) => state.graphu.shouldRerender;

export default graphuSlice.reducer;
