import { useTranslation } from "react-i18next";
import SearchableDropdown from "src/common/components/SearchableDropdown/SearchableDropdown";
import { Tooltip } from "../Tooltip/Tooltip";

type AssignToProjectGroupProps = {
  name: string;
  setName: (value: string) => void;
  assignedToProjectGroup: string;
  setAssignedToProjectGroup: (id: string) => void;
  errorMessage: string;
  setSelectedOption: (option: string) => void;
  projectGroups: { id: number; name: string }[];
  validateAndSubmit: () => void;
};

const AssignToProjectGroup = ({
  name,
  setName,
  assignedToProjectGroup,
  setAssignedToProjectGroup,
  errorMessage,
  setSelectedOption,
  projectGroups,
  validateAndSubmit,
}: AssignToProjectGroupProps) => {
  const { t } = useTranslation();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace") {
      event.stopPropagation();
    }
  };

  const handleCancel = () => {
    setSelectedOption("");
    setAssignedToProjectGroup("");
  };

  const InputField = () => (
    <div className="flex w-full flex-col items-start mb-4">
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={t("chooseTemplateType.createCourseFromTemplateModel.nameProject")}
        className="border rounded-md px-3 py-2"
        autoFocus
      />
    </div>
  );

  const isDisabled = !assignedToProjectGroup || !!errorMessage;

  return (
    <div className="w-[80%] flex flex-col mt-5 items-center justify-center 1536px:w-full">
      <h2 className="text-lg font-bold mb-2">
        {t("chooseTemplateType.createCourseFromTemplateModel.nameAndAssignNewProject")}
      </h2>
      <div className="w-[70%] mt-2 1536px:w-[50%] 1536px:mt-3">
        <InputField />
      </div>
      <div className="w-[70%] 1536px:w-[50%]">
        <SearchableDropdown
          options={projectGroups}
          selectedId={assignedToProjectGroup}
          onSelect={(selectedId) => setAssignedToProjectGroup(selectedId.toString())}
          placeholder={t("chooseTemplateType.createCourseFromTemplateModel.selectAProjectGroup")}
          searchPlaceholder={t(
            "chooseTemplateType.createCourseFromTemplateModel.searchAProjectGroup",
          )}
          noItemFound={t("pages.chat.createProjectFromAiGraph.noCourseFound")}
        />
      </div>
      <p className="text-red-500 text-center text-sm mt-3 h-6 w-full whitespace-nowrap">
        {errorMessage || "\u00A0"}
      </p>
      <div className="flex w-[80%] justify-center mt-1 1536px:mt-2 gap-5">
        <button
          className="btn-primary-fill bg-white text-black border hover:bg-gray-100"
          onClick={handleCancel}
        >
          {t("general.cancel")}
        </button>
        {!isDisabled ? (
          <button
            className="btn-primary-fill bg-green-500 text-white border border-green-500 hover:bg-green-600 py-2 px-4 rounded-md"
            onClick={validateAndSubmit}
          >
            {t("general.confirm")}
          </button>
        ) : (
          <Tooltip
            className="flex justify-center"
            message={t("chooseTemplateType.createCourseFromTemplateModel.cannotContinue")}
          >
            <button
              className="btn-primary-fill bg-gray-300 text-gray-500 border border-gray-300 hover:bg-gray-400 cursor-not-allowed py-2 px-4 rounded-md"
              disabled
            >
              {t("general.confirm")}
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default AssignToProjectGroup;
