import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProjectFromTemplate } from "src/common/components/Button/ButtonSquareIconsProject";
import AssignToProjectGroup from "src/common/components/Modal/AssignProjectGroup";
import GenerateProjectGroup from "src/common/components/Modal/GenerateProjectGroup";
import { Tooltip } from "src/common/components/Tooltip/Tooltip";
import { useAppDispatch } from "src/common/state/hooks";
import { closeStandardModal } from "src/common/state/slice/modal/modalSlice";
import { RootState } from "src/common/state/store";
import { setAlert } from "src/features/alert/state/alertsSlice";
import {
  fetchAllCurrentElements,
  fetchSpecificProjectGroupWithProjects,
  getAllProjectGroupsWithNestedElements,
  setSelectedProjectGroupFromProjectGroupId,
  updateProjectGroup,
} from "src/features/projectGroups/state/projectGroupsSlice";
import { PROJECT_LIST_COMPONENT_CLONE_FROM_ASSIGN_ID } from "src/pages/Projects/ProjectsList";
import {
  duplicateProject,
  setLastCreatedProjectId,
  setLastCreatedProjectName,
} from "../state/projectsSlice";

const CreateProjectGroupFromTemplate = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const projectGroupsWithNestedElements = useSelector((state: RootState) =>
    getAllProjectGroupsWithNestedElements(state),
  );
  const selectedTemplateId = useSelector((state: RootState) => state.projects.currentTemplateId);

  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [assignedToProjectGroup, setAssignedToProjectGroup] = useState("");

  const handleValidation = () => {
    if (!name) {
      setErrorMessage(t("error.emptyName"));
      return false;
    }
    if (name.length > 24) {
      setErrorMessage(t("error.nameTooLong"));
      return false;
    }
    return true;
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleAssignToProjectGroup = async (projectGroupId) => {
    if (isLoading) return;

    setIsLoading(true);

    dispatch(closeStandardModal(createProjectFromTemplate));
    await fetchProjectGroupData(projectGroupId); // to fetch all the project-group data before navigating to the project-group itself otherwise the current project group isn't updated.
    navigate(`/courses/${projectGroupId}/list`);

    try {
      await Promise.all([
        dispatch(
          updateProjectGroup({
            id: projectGroupId,
            payload: {
              project_group_elements: [{ templateId: selectedTemplateId, projectGroupId }],
            },
          }),
        ),

        dispatch(
          duplicateProject({
            project: selectedTemplateId,
            thumbnail: 47,
            projectGroup: projectGroupId,
            newName: `${name}`,
            enabledLanguagesIds: ["1"],
            componentId: PROJECT_LIST_COMPONENT_CLONE_FROM_ASSIGN_ID,
          }),
        ),
      ]);

      fetchProjectGroupData(projectGroupId);

      dispatch(
        setAlert({
          msg: t("chooseTemplateType.createCourseFromTemplateModel.projectGeneratedSuccessfully"),
          type: "success",
          isOpen: true,
          customTimeoutInMs: 15000,
        }),
      );
    } catch (error) {
      console.error("Error in validateAndSubmit:", error);
      dispatch(
        setAlert({
          msg: t("pages.chat.createProjectFromAiGraph.errorWhileAssigningModule"),
          type: "error",
          isOpen: true,
          alertShowCloseButton: true,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateProjectGroup = async () => {
    try {
      setName("");
      dispatch(closeStandardModal(createProjectFromTemplate));
      dispatch(setLastCreatedProjectId(selectedTemplateId));
      dispatch(setLastCreatedProjectName(name));
      navigate("/courses/new", { state: { cloneProject: true } });
    } catch (error) {
      handleError();
    }
  };

  const fetchProjectGroupData = async (projectGroupId) => {
    dispatch(setSelectedProjectGroupFromProjectGroupId(projectGroupId));

    await Promise.all([
      dispatch(fetchAllCurrentElements({ projectGroupId })),
      dispatch(fetchSpecificProjectGroupWithProjects({ projectGroupId })),
    ]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      event.stopPropagation();
    }
  };

  const handleError = () => {
    dispatch(
      setAlert({
        msg: t("pages.error.genericError"),
        type: "error",
        isOpen: true,
        alertShowCloseButton: true,
        customTimeoutInMs: 10000,
      }),
    );
  };

  const validateAndSubmit = () => {
    if (!handleValidation() || errorMessage) {
      return;
    }
    if (selectedOption === "assignToProjectGroup" && assignedToProjectGroup) {
      handleAssignToProjectGroup(Number(assignedToProjectGroup));
    }
    if (selectedOption === "generateProjectGroup") {
      handleGenerateProjectGroup();
    }
  };

  const InputField = () => (
    <div className="flex w-full flex-col items-start mb-4">
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={t("chooseTemplateType.createCourseFromTemplateModel.nameProject")}
        className="border rounded-md px-3 py-2"
        autoFocus
      />
      <p className="text-red-500 text-sm mt-5 h-6 text-center w-full whitespace-nowrap">
        {errorMessage || "\u00A0"}
      </p>
    </div>
  );

  const projectGroups = projectGroupsWithNestedElements
    ? projectGroupsWithNestedElements
        .filter((pg) => pg && pg.name)
        .map((projectGroup) => ({
          id: projectGroup.id,
          name: projectGroup.name,
        }))
    : [];

  useEffect(() => {
    if (name.length <= 0) {
      setErrorMessage(t("error.emptyName"));
      return;
    }
    if (name.length > 24) {
      setErrorMessage(t("error.nameTooLong"));
      return;
    }
    const existingProjects = projectGroupsWithNestedElements
      .find((pg) => Number(pg.id) === Number(assignedToProjectGroup))
      ?.project_group_elements.map((pge) => pge.project);

    if (existingProjects && existingProjects.some((project) => project.name === name)) {
      setErrorMessage(t("alerts.aProjectWithThisNameAlreadyExistsInTheProjectGroup"));
    } else {
      setErrorMessage("");
    }
  }, [name, assignedToProjectGroup, projectGroupsWithNestedElements, t]);

  const renderAppropriateComponent = () => {
    const isDisabled = !name || !!errorMessage;

    switch (selectedOption) {
      case "assignToProjectGroup":
        return (
          <AssignToProjectGroup
            name={name}
            setName={setName}
            assignedToProjectGroup={assignedToProjectGroup}
            setAssignedToProjectGroup={setAssignedToProjectGroup}
            errorMessage={errorMessage}
            setSelectedOption={setSelectedOption}
            projectGroups={projectGroups}
            validateAndSubmit={validateAndSubmit}
          />
        );

      case "generateProjectGroup":
        return <GenerateProjectGroup name={name} setName={setName} errorMessage={errorMessage} />;

      default:
        return (
          <div className="w-full mt-5 flex flex-col items-center justify-center">
            <h2 className="text-lg font-bold mb-4 mt-2 1536px:mt-3">
              {t("chooseTemplateType.createCourseFromTemplateModel.nameNewProject")}
            </h2>
            <div className="w-[40%] mt-1">
              <InputField />
            </div>
            <div className="flex w-full items-center justify-center gap-2 1536px:mt-2">
              {isDisabled ? (
                <div
                  className="tooltip flex flex-col items-center"
                  data-tooltip={t("chooseTemplateType.createCourseFromTemplateModel.enterName")}
                >
                  <Tooltip
                    className="flex justify-center"
                    message={t("chooseTemplateType.createCourseFromTemplateModel.typeProjectName")}
                  >
                    <button
                      className="btn-primary-fill bg-gray-300 text-gray-500 border border-gray-300 hover:bg-gray-400 cursor-not-allowed 1024px:py-3 1536px:py-2 text-center"
                      disabled
                    >
                      {t("chooseTemplateType.createCourseFromTemplateModel.generateProjectGroup")}
                    </button>
                  </Tooltip>
                </div>
              ) : (
                <button
                  className="btn-primary-fill 1024px:py-3 1536px:py-2 text-center"
                  onClick={() => {
                    if (handleValidation()) {
                      handleGenerateProjectGroup();
                    }
                  }}
                >
                  {t("chooseTemplateType.createCourseFromTemplateModel.generateProjectGroup")}
                </button>
              )}

              {isDisabled ? (
                <div
                  className="tooltip flex flex-col items-center"
                  data-tooltip={t("chooseTemplateType.createCourseFromTemplateModel.enterName")}
                >
                  <Tooltip
                    className="flex justify-center"
                    message={t("chooseTemplateType.createCourseFromTemplateModel.typeProjectName")}
                  >
                    <button
                      className="btn-primary-fill bg-gray-300 text-gray-500 border border-gray-300 hover:bg-gray-400 cursor-not-allowed 1024px:py-3 1536px:py-2 text-center"
                      disabled
                    >
                      {t("chooseTemplateType.createCourseFromTemplateModel.assignToProjectGroup")}
                    </button>
                  </Tooltip>
                </div>
              ) : (
                <button
                  className="btn-primary-fill 1024px:py-3 1536px:py-2 text-center bg-white text-green-500 border border-green-500 hover:bg-green-100"
                  onClick={() => {
                    if (handleValidation()) {
                      setSelectedOption("assignToProjectGroup");
                    }
                  }}
                >
                  {t("chooseTemplateType.createCourseFromTemplateModel.assignToProjectGroup")}
                </button>
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-center">
      {renderAppropriateComponent()}
    </div>
  );
};

export default CreateProjectGroupFromTemplate;
