import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentProjectSizeInMbs } from "./state/projectsSliceLegacy";
import { loadGraph, resetGraphInfo } from "./state/graphLegacy";
import { getAllMedia as fetchMedias } from "../gallery/state/gallerySlice";
import SVGGraphContainer from "./components/SVGGraphContainer";
import { getIsLoading, getShouldRerenderGraph, setIsLoading } from "./state/graphuSlice";
import { MEDIA_LIST_COMPONENT_ID } from "../../features/gallery/Gallery";
import "./index.scss";
import { AppDispatch } from "../../common/state/store";
import { currentOrgSelector } from "../../common/state/selectors/authSelector";
import { getCurrentProject } from "../projects/state/projectsSlice";
import { prettifyErrorMessage } from "src/common/util/prettifyErrorMessage";
import { setAlert } from "../alert/state/alertsSlice";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IEditProjectScenesProps {}

const EditProjectScenes: React.FunctionComponent<IEditProjectScenesProps> = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentProject = useSelector(getCurrentProject);
  const org = useSelector(currentOrgSelector);

  const shouldRerender = useSelector(getShouldRerenderGraph);
  const isLoading = useSelector(getIsLoading);

  // on mount we (pre/re-)fetch all the medias
  useEffect(() => {
    if (org) {
      dispatch(
        fetchMedias({
          orgId: Number(org.id),
          componentId: MEDIA_LIST_COMPONENT_ID,
        }),
      );
    }
  }, [dispatch, org]);

  // const [error,setError] = React.useState(null as any);
  const error: any = null;

  React.useCallback(() => {
    if (error) {
      throw JSON.parse(error);
    }
  }, [error]);

  const loadGraphFct = () => {
    // before loading we reset the graph info
    // ➡︎ this is to avoid displaying the "wrong" graph while loading or on failure
    dispatch(resetGraphInfo());

    // fetch the project info from the server
    if (currentProject) {
      dispatch(setIsLoading(true));
      dispatch(loadGraph(currentProject.id))
        .then((res: any) => {
          if (res?.payload?.data?.errorMessages?.length > 0) {
            const prettyErrorMessage = prettifyErrorMessage(
              res.payload.data.errorMessages.join(", "),
            );

            dispatch(
              setAlert({
                msg: prettyErrorMessage.msg,
                subMsg: prettyErrorMessage.subMsg,
                type: "warning",
                isOpen: true,
                customTimeoutInMs: 10000,
              }),
            );
          }

          if (res?.payload?.data?.sizeInMbs) {
            const sizeInMbs = res?.payload?.data?.sizeInMbs;
            dispatch(setCurrentProjectSizeInMbs(sizeInMbs));
          }

          return res;
        })
        // .catch((e: any) => console.error("EREROR CATCH", e))
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    }
  };

  // and we load the project
  useEffect(() => {
    loadGraphFct();
  }, [dispatch, shouldRerender]);

  // we do NOT hold the state in the page: it's completely pushed into redux and the (following) containers do the wiring
  return (
    <div className="graph-upper-div ml-[10%]" id="graph-wrapper" style={{ height: "inherit" }}>
      <SVGGraphContainer loading={isLoading} />
    </div>
  );
};
export default EditProjectScenes;
