import { useTranslation } from "react-i18next";

type GenerateProjectGroupProps = {
  name: string;
  setName: (name: string) => void;
  errorMessage: string;
};

const GenerateProjectGroup = ({ name, setName, errorMessage }: GenerateProjectGroupProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-70 flex flex-col items-center justify-center">
      <h2 className="text-lg font-bold mb-4">
        {t("chooseTemplateType.createCourseFromTemplateModel.nameNewProject")}
      </h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={t("chooseTemplateType.createCourseFromTemplateModel.nameProject")}
        className="border rounded px-3 py-2 w-full"
        autoFocus
      />
      {errorMessage && <p className="text-red-500 text-sm mb-2">{errorMessage}</p>}
    </div>
  );
};

export default GenerateProjectGroup;
