import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../common/state/store";
import { useForm } from "react-hook-form";
import AccessTypeTable from "../../features/projects/components/AccessTypeTable";
import {
  getNewProjectGroup,
  INewProjectGroup,
  postProjectGroup,
  setCurrentElements,
  setNewProjectGroup,
  setSelectedProjectGroup,
  fetchAllCurrentElements,
} from "../../features/projectGroups/state/projectGroupsSlice";
import { useAppDispatch } from "../../common/state/hooks";
import { getCurrentOrganization, getCurrentUser } from "../../features/profile/state/profileSlice";
import { FooterButtons } from "../../common/components/FooterButtons";
import InfoButton from "../../common/components/InfoButton/InfoButton";
import { IsLoading } from "../../common/components/AppState/IsLoading";
import { duplicateProject } from "../../features/projects/state/projectsSlice";
import { PROJECT_LIST_COMPONENT_CLONE_LOADING_ID } from "../Projects/ProjectsList";
import { setAlert } from "src/features/alert/state/alertsSlice";
interface ITemplateProjectAccess {
  decrementStep(): void;
}

interface LocationState {
  cloneProject?: boolean;
}

export const PROJECT_GROUP_CREATE = "projectGroupCreate";

const TemplateProjectAccess = ({ decrementStep }: ITemplateProjectAccess) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation() as { state?: LocationState };
  const currentUser = useSelector(getCurrentUser);
  const currentOrg = useSelector(getCurrentOrganization);
  const lastClonedProjectId = useSelector((state: RootState) => state.projects.lastClonedProjectId);
  const lastClonedProjectName = useSelector(
    (state: RootState) => state.projects.lastClonedProjectName,
  );
  const { newProjectGroup } = useSelector((state: RootState) => ({
    newProjectGroup: getNewProjectGroup(state),
  }));
  const { handleSubmit, setValue, watch } = useForm<INewProjectGroup>();
  const cloneProject = location.state?.cloneProject || false;

  const onSubmit = async (data: INewProjectGroup) => {
    if (!currentOrg) return;

    dispatch(
      postProjectGroup({
        name: newProjectGroup.newName,
        source_thumbnail: newProjectGroup.thumbnailId,
        organization: Number(currentOrg.id),
        auth_type: data?.accessType ? data.accessType : "classic",
        componentId: PROJECT_GROUP_CREATE,
        theme_info: newProjectGroup.themeId,
        created_by: currentUser?.email ? currentUser.email : "",
        source_app_main_menu_media: Number(newProjectGroup.menuMediaId),
      }),
    )
      .then((res: any) => {
        const projectGroupId = res?.payload?.data?.id;

        dispatch(setSelectedProjectGroup(res.payload.data));

        if (cloneProject && lastClonedProjectId) {
          dispatch(
            duplicateProject({
              project: lastClonedProjectId,
              thumbnail: 47,
              projectGroup: projectGroupId,
              newName: lastClonedProjectName,
              enabledLanguagesIds: ["1"],
              componentId: PROJECT_LIST_COMPONENT_CLONE_LOADING_ID,
            }),
          )
            .then(() => {
              dispatch(fetchAllCurrentElements({ projectGroupId })),
                dispatch(
                  setAlert({
                    msg: t(
                      "chooseTemplateType.createCourseFromTemplateModel.projectGeneratedSuccessfully",
                    ),
                    type: "success",
                    isOpen: true,
                    alertShowCloseButton: true,
                    customTimeoutInMs: 10000,
                  }),
                );
            })
            .catch((error) => {
              console.error("Error while cloning the project", error);
            });
        }

        dispatch(
          setNewProjectGroup({
            accessType: "classic",
            newName: "",
            thumbnailId: 47,
            themeId: 2,
            menuMediaId: 0,
          }),
        );

        dispatch(setCurrentElements([]));
        navigate(`/courses/${projectGroupId}/list`);
      })
      .catch((error) => {
        console.error("Error in validateAndSubmit:", error);
        dispatch(
          setAlert({
            msg: t("pages.chat.createProjectFromAiGraph.errorWhileGenerating"),
            type: "error",
            isOpen: true,
            alertShowCloseButton: true,
          }),
        );
      });
  };

  return (
    <>
      <IsLoading componentId={PROJECT_GROUP_CREATE} showSuccess={false} spinnerPlaceholder>
        <h1 className="text-2xl font-medium w-full max-w-7xl mx-auto mt-6 mb-4 px-6 xl:px-3">
          {t("chooseTemplateType.accessType")} :
        </h1>
        <form
          className="flex flex-col grow w-full h-full justify-between items-center overflow-y-auto personalize-scroll-visible"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InfoButton relevantArticle="howToChooseAuthMode" />
          <div className="px-6 xl:px-3">
            <AccessTypeTable setValue={setValue} watch={watch} />
          </div>

          <FooterButtons
            cancelText={t("general.cancel")}
            cancelCb={() => navigate("/courses/")}
            backText={t("general.back")}
            backCb={() => decrementStep()}
            nextText={t("general.create")}
          />
        </form>
      </IsLoading>
    </>
  );
};

export default TemplateProjectAccess;
